sidebar {
    .box {
        margin-top: 30px;
        width: 100%;
        padding: 23px;
        box-sizing: border-box;
        background: rgba(255,255,255,0.02);

        h1 {
            font-size: 16px;
            margin: 0;
            padding: 0;
            font-family: $roboto;
            margin-bottom: 10px;
        }

        p {
            padding: 0;
            margin: 0;
            font-family: $opensans;
            font-size: 12px;
            line-height: 16px;
            color: $purple-grey;
            margin-bottom: 10px;
        }

        &.white {
            background: $white;
            color: $purple-main;
            margin-top: 10px;
            @include clearfix;

            h1 {
                font-size: 15px;
                margin-bottom: 10px;
            }

            p {
                color: $purple-main;
                font-size: 11px;
                margin-bottom: 13px;
            }

            .btn {
                font-size: 10px;
                padding: 8px 20px;
            }

            &.first {
                margin-top: -3px;
            }

            &.styleguide {
                margin-top: 25px;
            }
        }

        .form-group {
            margin-top: 20px;
            margin-bottom: 15px;

            .control-label {
                font-size: 11px;
                color: $white!important;
                top: -8px;
            }

            input {
                line-height: 1em;
                font-size: 12px;
                color: $purple-grey;
            }

            .bar {
                border-bottom-color: $purple-grey;
            }
        }
    }

    nav {
        margin: 0;
        padding: 0;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: block;
                @include clearfix;

                a {
                    color: $purple-main;
                    font-family: $roboto;
                    font-size: 12px;
                    display: block;
                    line-height: 20px;
                    height: 20px;
                    float: left;
                    text-decoration: none;
                }

                &.active {
                    background: $dots_article repeat-x center center;

                    a {
                        background: #e9e9ec;
                        padding-right: 15px;
                        font-weight: bold;
                        color: $purple-main!important;
                    }
                }

                &:hover {
                    a {
                        color: #da1f4f;
                    }
                }
            }
        }
    }

    .social_print {
        padding: 9px 0 0;
        @include clearfix;

        nav.share {
            float: left;

            a {
                display: inline-block;
                width: 25px;
                height: 25px;
                margin-right: 3px;
                overflow: hidden;
                background: url("../img/social.png") no-repeat left top;
                text-indent: -99999px;

                &:hover {
                    opacity: 0.7;
                }

                &.email {
                    background-position: right top;
                    width: 27px;
                }

                &.twitter {
                    background-position: -61px top;
                    width: 27px;
                }

                &.linkedin {
                    background-position: -30px top;
                }
            }
        }

        .print {
            display: block;
            float: right;
            width: 19px;
            height: 19px;
            background: url("../img/print.png") no-repeat left center;
            overflow: hidden;
            text-indent: -9999px;
            cursor: pointer;

            &:hover {
                background: url("../img/print.png") no-repeat right center;
            }
        }
    }
}

.mc4wp-form-submitted {
    .ls mc4wp-form-fields {
        display: none;
    }
}

.mc4wp-alert {
    margin-top: 20px;
}

.btn_sidebar_info {
    float: none;
    margin-top: 20px;
    color: $white;
    text-align: center;

    &:hover {
        color: $purple-main;
        border-color: $white;
        background: $white;
    }
}

.mc4wp-form-fields {
    button {
        color: $white;

        &:hover {
            background: $white;
            color: $purple-main;
        }
    }
}

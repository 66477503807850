.loading{
  display: none;
  position: fixed;
  left:0px;
  top:0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  z-index: 1000;
}
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 48vh auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.form{
  font-family: $opensans;
  .submitNote{
    clear: both;
    font-size: 11px; padding-top: 40px;
  }
  h1{
    font-family: $roboto;
    font-size: 16px;
    font-weight: bold;
    clear: both;
    span{
      float: right;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #b3b3b3;
      margin-top: 6px;
    }
  }
  hr{
    clear: both;
    border:none;
    background: $dots_article repeat-x left top;
    height: 1px;
    &+h1{
      margin-top: 60px;
    }
  }
  .btn#mybut{
    margin-top: 40px;
  }
  .form-row{
    @include clearfix;
    .col-100{
      width: 100%
    }
    .col-50{
      width: 50%;
      float: left;
      padding-right: 20px;
      box-sizing: border-box;
      &:nth-of-type(2){
        padding-right: 0px; padding-left: 20px;
      }
      @include breakpoint($small){
        float: none;
        width: 100%;
        padding: 0px!important;
      }
    }
    table{
      margin-top: 20px;
      td{
        font-size: 13px;
        padding:5px 0px;
        padding-right: 20px;
        line-height: 20px;
        .form-radio{
          padding:0px; margin:0px;
          .radio{
            float: left; width: 60px;
            margin: 0px;
          }
        }
      }
    }
    .file{
      @include clearfix;
      padding: 20px 0px 20px 0px;

      display: block;
      font-size:13px;
      span{float: left; margin-right: 10px; height: 35px; line-height: 35px;}
      input{opacity: 0; width: 1px; height: 1px; position: absolute; transform:translate(20px, 20px) }
      #fileInfo{
        clear: both;
        height: 20px;
        width: 100%;
        line-height: 20px;
        font-family: $opensans;
        font-size: 13px;
      }
    }
    &.captcha-row{
      margin-top: 25px;
    }
  }
}
.radio input{opacity: 0;}
.radio input:checked~.helper:before{color: $purple-main;}
.radio input:checked~.helper:after{border-color: $purple-main; background-color: $purple-main;}
.emptyfielderror, .captchaerror{
  margin: 10px 0px;
  display: none;
  color: $pink-main;
  font-size: 13px;
  font-family: $opensans;

}
.form{
  .success{
    display: none;
    clear: both;
    h2{
      font-family: Roboto Slab,serif;
font-size: 16px;
font-weight: 700;
clear: both;
    }
    p{

      font-size: 13px;
line-height: 20px;
font-family: Open Sans,sans-serif;
padding: 0 0 1.5em;
margin: 0;
    }
  }
}

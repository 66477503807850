.homepage {
    $dots_section: url("../img/dots-section-homepage.png");
    $dots_article:url("../img/dots-menu.png");
    sidebar {
        .box {
            form {
                .form-group {
                    i {
                        border-color: #444356;
                    }
                }

                button {
                    border-color: #444356;
                }
            }
        }
    }

    .row {
        sidebar {
            section {
                header {
                    background-image: $dots_section;
                }
            }
        }

        .main_container {
            section {
                header {
                    background-image: $dots_section;
                }
            }
        }
    }

    .article_grid {
        article {
            background: $dots_article bottom left repeat-x;
        }
    }

    sidebar {
        article {
            background: $dots_article bottom left repeat-x;
            a{
              text-decoration: underline;
            }
        }
    }
    article {
      h1{
        color: $white;
      }
      h3{
        color: $purple-grey;
      }
    }
}

.main_container{
  .single-content{
    padding-top: 36px;
    background: $dots_section repeat-x left top;
  }
}
sidebar{
  &.single-content{
    padding-top: 36px;
    background: $dots_section repeat-x left top;
    margin-top: $section_padding;
  }
}

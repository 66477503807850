.wyswig {
    p {
        font-size: 13px;
        line-height: 20px;
        font-family: $opensans;
        padding: 0 0 1.5em;
        margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $roboto;
        margin: 1em 0 0.2em;
        padding: 0 0 16px;

        &.margin-top-0 {
            margin-top: 0;
        }
    }

    a {
        color: inherit;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    sup {
        font-weight: bold;

        a {
            text-decoration: none;

            &:hover {
                color: $pink-main;
            }
        }
    }

    ol,
    ul {
        font-family: $opensans;
        font-size: 13px;
        line-height: 20px;
        padding: 0 0 1.5em 15px;
        font-weight: normal;
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            ol {
                list-style-type: lower-alpha;
                font-weight: normal;
                font-style: italic;
                padding: 20px 0 10px 20px;

                span {
                    font-style: normal;
                }
            }
        }
    }
}

.toggle {
    margin-bottom: 50px;

    header {
        @include clearfix;
        padding-bottom: 15px;
        padding-top: 30px;
        background: $dots_article bottom left repeat-x;

        h1 {
            font-size: 18px;
            float: left;
            margin: 0;
            padding: 0;
            font-family: $roboto;
        }

        .viewmore {
            color: $purple-main;
            font-size: 10px;
            font-weight: 200;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            line-height: 25px;
            float: right;
            height: 18px;
            position: relative;
            padding-right: 15px;
            cursor: pointer;

            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 12px;
                height: 1px;
                width: 9px;
                background: $purple-main;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                right: 4px;
                top: 8px;
                height: 9px;
                width: 1px;
                background: $purple-main;
            }

            .less {
                display: none;
            }

            .more {
                display: block;
            }

            &.less {
                &:before {
                    display: none;
                }

                .less {
                    display: block;
                }

                .more {
                    display: none;
                }
            }

            &:hover {
                color: $pink-main;

                &:after,
                &:before {
                    background: $pink-main!important;
                }
            }
        }
    }

    .content {
        background: $dots_article bottom left repeat-x;
        max-height: 0;
        padding: 0;
        transition: all 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
        visibility: hidden;

        table {
            width: 100%;
            font-size: 13px;
            line-height: 20px;
            font-family: $opensans;
            display: none;

            td {
                vertical-align: top;

                &.date {
                    font-style: italic;
                    width: 60px;
                    font-weight: bold;
                    font-size: 11px;
                }

                &.place {
                    width: 217px;
                }
            }
        }
    }

    .toggle-item {
        &.opened {
            .content {
                max-height: 1000000px;
                padding: 20px 0 31px;
                opacity: 1;
                visibility: visible;

                table {
                    display: table;
                }
            }
        }
    }
}

.lecture {
    border: 1px solid #5c5d6e;
    margin-top: 20px;
    margin-bottom: 50px;

    header {
        background: #5c5d6e;
        color: $white;
        padding: 18px 25px;
        font-family: $roboto;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        @include clearfix;

        span {
            display: block;
            float: right;
            height: 20px;
            font-size: 10px;
            line-height: 18px;
            box-sizing: border-box;
            border: 1px solid #8d8e9a;
            font-family: $opensans;
            text-transform: uppercase;
            padding: 0 12px;
            font-weight: 600;
            @include breakpoint($small) {
                clear: both;
                float: left;
                margin-top: 5px;
            }
        }
    }

    .lecture-content {
        padding: 25px;

        h1 {
            margin: 0;
            padding: 0;
            font-family: $roboto;
            font-size: 16px;
            font-weight: 600;
        }

        h2 {
            margin: 0;
            padding: 0;
            font-family: $playfair;
            font-size: 12px;
            font-weight: normal;
            font-style: italic;
            padding: 10px 0;
        }

        p {
            font-family: $opensans;
            line-height: 16px;
            font-size: 11px;
            padding: 10px 0 0;
            margin: 0;
        }
    }

    .lecture-footer {
        padding: 20px 0;
        margin: 0 25px;
        background: $dots_article repeat-x left top;

        div {
            display: inline-block;
            margin-right: 30px;
            font-size: 11px;
            font-family: $opensans;
            color: $purple-grey;

            strong {
                margin-right: 2px;
                color: $purple-main;
            }
        }
    }
}

.lectures {
    width: 100%;
    background: $dots_article repeat-x left top;
    margin-bottom: 50px;

    tr {
        background: $dots_article repeat-x left bottom;

        &.more {
            td {
                &:nth-of-type(2) {
                    .details {
                        display: block!important;
                    }
                }

                &:nth-of-type(3) {
                    .viewmore {
                        &:before {
                            display: none;
                        }

                        .less {
                            display: block;
                        }

                        .more {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    td {
        vertical-align: top;
        padding: 25px 0;
        border-spacing: 0;

        &:first-of-type {
            font-size: 11px;
            font-family: $opensans;
            font-style: italic;
            font-weight: 700;
            line-height: 22px;
            padding-right: 25px;
        }

        &:nth-of-type(2) {
            font-family: $roboto;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            max-width: 430px;

            span.author {
                display: block;
                font-size: 12px;
                font-family: $playfair;
                font-style: italic;
                font-weight: normal;
                padding-top: 5px;
            }

            .details {
                padding-top: 15px;
                display: none;

                p {
                    font-weight: normal;
                }
            }
        }

        &:nth-of-type(3) {
            text-align: right;
            width: 140px;

            i {
                display: inline-block;
                width: 17px;
                height: 14px;
                background: url("../img/speaker.png") no-repeat center top;
                background-size: 100% auto;
                cursor: pointer;

                &:hover {
                    background-position: center bottom;
                }

            }
            a.video + .viewmore{
                  margin-top: 10px!important;
              }
            .viewmore {
                color: $purple-main;
                font-size: 10px;
                font-weight: 200;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                line-height: 25px;
                display: block;
                height: 18px;
                margin-top: 0px;
                position: relative;
                padding-right: 15px;
                cursor: pointer;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    height: 1px;
                    width: 9px;
                    background: $purple-main;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 4px;
                    top: 8px;
                    height: 9px;
                    width: 1px;
                    background: $purple-main;
                }

                .less {
                    display: none;
                }

                .more {
                    display: block;
                }

                &.less {
                    &:before {
                        display: none;
                    }

                    .less {
                        display: block;
                    }

                    .more {
                        display: none;
                    }
                }

                &:hover {
                    color: $pink-main;

                    &:after,
                    &:before {
                        background: $pink-main!important;
                    }
                }
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.btn-fullarticle {
    margin-bottom: 70px;
    margin-top: -10px;
}

.forum_responses {
    clear: both;
}

.references {
    table {
        word-break: break-all;
    }
}

.contact-row{
  @include clearfix;
  @include breakpoint($big) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
  }
  padding-bottom: 55px;

  .col {

      box-sizing: border-box;

      padding-bottom: 30px;
      color: $purple-main;
      @include breakpoint($big) {
          width: 300px;
              border:1px solid #8d8992;
                  padding: 20px 25px 20px 25px;
      }
      &:nth-child(even) {
          margin-right: 0;
      }
      p{
        line-height: 20px;
        margin: 0px; padding: 0px;
        font-family: $opensans;
        font-size: 13px;
        a{
          line-height: 20px;
          margin: 0px; padding: 0px;
          font-family: $opensans;
          font-size: 13px;
          color: inherit;

        }
      }
      h1{
        font-size: 13px;
        line-height: 20px;
        margin: 10px 0px 0px 0px;
        padding: 0px;
      }

  }
}

section {
    &.review_page {
        margin-bottom: 70px;

        header {
            .review-nav {
                float: right;

                a {
                    color:$purple-main;
                    font-size: 10px;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    line-height: 25px;
                    height: 18px;
                    position: relative;

                    text-decoration: none;
                    &:hover{
                      color: $pink-main;
                    }
                    &:before {
                        width: 4px;
                        height: 6px;
                        position: absolute;
                        left: 0;
                        top: 5px;
                        content: "";
                        display: block;
                        background: url("../img/nav-arrow.png") no-repeat left top;
                        background-size: 100% auto;
                    }

                    &.next {
                        padding-right: 10px;
                        margin-left: 10px;
                        &:before {
                            left: auto;
                            right: 0;
                            transform: rotate(180deg);
                        }
                    }
                    &:hover{
                      color: $pink-main;
                      &:before{
                        background-position: left bottom;
                      }
                    }
                    &.prev {

                        padding-left: 10px;
                    }
                }
            }
        }
    }
}

sidebar {
    .the_review_summary {
        background: #5c5d6e;
        color: rgba(255,255,255,0.6);
        margin-top: 35px!important;

        h1{
          color: $white;
        }
        table{
          font-size: 11px;
          font-family: $opensans;
          th{
            text-align: left;
            font-weight: bold;
            padding-right: 20px;
          }
        }
    }
}

.abstract{
  padding: 20px 0px 0px 0px;

}
i.ico.href{
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url(../img/href.png) no-repeat center center ;
    margin-left: 5px;
    transform: translateY(1px);
  }

footer {
    font-family: $opensans;
    font-size: 10px;
    padding-top: 95px;

    .apps {
        color: $purple-main;
        float: right;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        height: 45px;

        @include breakpoint($small) {
            height: 40px;
        }

        .itunes {
            padding-right: 20px;
            background: url("../img/appstore.svg") no-repeat right top;
            background-size: 96px 29px;
            cursor: pointer;
            width: 96px;
            height: 29px;
            display: inline-block;
            float: right;

            @include breakpoint($small) {
                width: 80px;
                height: 24px;
                background-size: 80px 24px;
                padding-right: 10px;
            }
        }

        .googleplay {
            padding-right: 20px;
            background: url("../img/googleplay.svg") no-repeat right top;
            background-size: 102px 29px;
            cursor: pointer;
            width: 102px;
            height: 29px;
            display: inline-block;
            float: right;

            @include breakpoint($small) {
                width: 80px;
                height: 24px;
                background-size: 80px 24px;
                padding-right: 10px;
            }
        }

        p {
            line-height: 46px;
            margin: 0;
            text-decoration: none;
            float: right;
            display: inline-block;

            @include breakpoint($small) {
                visibility: hidden;
                width: 1px;
                height: 46px;
            }
        }
    }


    nav.social {
        @include breakpoint($big) {
            text-align: right;
        }
        max-width: 60px;
        float: left;
        @include clearfix;
        margin-top: 8px;

        a {
            display: block;
            float: left;
            margin-left: 0;
            margin-right: 3px;
            @include breakpoint($big) {
                float: left;
                margin-right: 0;
                margin-left: 3px;
            }
            width: 20px;
            height: 20px;
            background: $purple-main;
            text-indent: -9999px;
            overflow: hidden;
            background-size: 20px 20px;

            &.facebook {
                background: url("../img/ico_facebook-dark.png") no-repeat center center;
            }

            &.twitter {
                background: url("../img/ico_twitter-dark.png") no-repeat center center;
            }
        }
    }

    .end {
        clear: both;
        margin-top: 15px;
        border-top: 3px solid $purple-main;
        padding-top: 15px;
        padding-bottom: 45px;
        font-weight: 200;
        letter-spacing: 0.01em;

        a {
            display: block;
            text-decoration: none;
            color: $purple-main;
        }

        p {
            padding: 0;
            margin: 0;
            color: $purple-main;
            max-width: 70%;
            line-height: 1.3;
        }
        @include clearfix;
    }
}

.homepage {
    footer {
        nav.social {
            a {
                &.facebook {
                    background: url("../img/ico_facebook-light.png") no-repeat center center;
                }

                &.twitter {
                    background: url("../img/ico_twitter-light.png") no-repeat center center;
                }
            }
        }
        background: $purple-main;

        .end {
            border-top-color: $pink-main;

            a,
            p {
                color: #8d8992;
            }
        }
        
        .apps p {
            color: #8d8992;
        }
    }
}

header.top {
    background: $purple-main;
    color: $white;
    border-bottom: 1px solid $purple-main;
    position: relative;
    z-index: 10;
    transition: border 0.5s ease-out;
    @include breakpoint ($big) {
        padding: 32px 0 22px;
    }
    @include breakpoint ($small) {
        padding: 20px 0;
    }

    .inner {
        .logo {
            img {
                @include breakpoint($small) {
                    width: 165px;
                }
            }
        }

        nav {
            a {
                font-family: $opensans;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.01em;
                position: relative;
                line-height: 14px;
                height: 19px;
                display: block;
                float: left;
                padding-top: 2px;
                box-sizing: border-box;
                text-indent: -9999px;
                @include breakpoint($small) {
                    width: 20px;
                    margin-left: 20px;
                }
                @include breakpoint($big) {
                    margin-left: 77px;
                    padding-left: 30px;
                    text-indent: 0;
                    margin-top: 10px;
                }

                &:before {
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-position: center right!important;
                    @include breakpoint($big) {
                        width: 30px;
                        height: 30px;
                    }
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 0;
                }

                &.toggle_search {
                    background: url("../img/search.png") no-repeat left center;
                }

                &.toggle_menu {
                    background: url("../img/menu.png") no-repeat left center;
                }
            }
        }
    }
}

nav.main {
    box-shadow: 0 200px 100px 50px rgba(0,0,0,0.2);
    position: absolute;
    width: 100%;
    background: $purple-main;
    font-family: $opensans;
    top: 60px;
    min-height: 100vh;
    margin-top: -300vh;
    padding: 0 20px 20px 0;
    transition: margin-top 0.5s ease-out;
    z-index: 1000;
    @include breakpoint($big) {
        margin-top: -300vh;
        top: 83px;
        padding: 55px 0;
        min-height: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        @include clearfix;

        li {
            width: 100%;
            margin-bottom: 10px;

            &:nth-of-type(3n+3) {
                margin-right: 0;
            }
            @include breakpoint($big) {
                width: 300px;
                float: left;
                margin-right: 20px;
                margin-bottom: 50px;
            }

            a {
                color: $white;
                text-decoration: none;
                display: block;
                padding: 10px 0;
                @include breakpoint($big) {
                    padding: 15px 0 30px;
                }
                background: url("../img/dots-menu.png") repeat-x left bottom;
                box-sizing: border-box;
                position: relative;
                cursor: pointer;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 0px;
                    border-bottom: 1px dotted $pink-main;

                    width: 0;
                    transition: width 0.2s ease-out;
                }

                &:hover {
                    color:$pink-main;
                    &:after {
                        width: 100%;
                    }
                }

                strong {
                    display: block;
                    font-family: $roboto;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    padding-bottom: 8px;
                }

                span {
                    font-size: 11px;
                    color: $purple-grey;
                    line-height: 11px;
                    display: block;
                }

                &:hover {}
            }
        }
    }
}

body.menu {
    nav.main {
        margin-top: 0;
    }

    header.top {
        a {
            &.toggle_menu {
                background: url("../img/menu-close.png") no-repeat left center;
                background-size: auto auto;
            }

            &.toggle_search {
                display: none;
            }
        }
    }
}
body.search{
  .search_area{

    @include breakpoint ($big) {
        padding: 32px 0 22px;
    }
    @include breakpoint ($small) {
        padding: 20px 0;
    }
    opacity: 1;
    visibility: visible;

  }
}
.homepage {
    &:not(.menu) {
        .search_area,
        header.top {
            border-bottom: 1px solid $pink-main;
        }
        .search_area{
            .toggle_search{
              color: $white;
            }
        }
    }
}

.search_area {
    color: $white;
    border-bottom: 1px solid $purple-main;
    position: relative;
    z-index: 0;

    transition:all 0.3s ease-out;

    box-sizing: border-box;
    opacity: 0;
    padding: 0px;
    visibility: hidden;
    input {
        background: transparent;
        border: none;
        font-family: $opensans;
        color: $purple-grey;
        padding: 0;
        margin: 0;
        font-size: 24px;
        height: 30px;
        outline: none;
    }
    .toggle_search{
      float: right;
      font-size: 10px;
      text-transform: uppercase;
      font-family: $opensans;
      letter-spacing: 0.05em;
      margin-top: 20px;
      color: $purple-grey;
    }
    ::-webkit-input-placeholder {
        color: rgba(122,122,122,.2);
    }

    :-moz-placeholder {
        /* Firefox 18- */
        color: rgba(122,122,122,.2);
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(122,122,122,.2);
    }

    :-ms-input-placeholder {
        color: rgba(122,122,122,.2);
    }
}

.forum-top-article {
    article {
        padding-bottom: 30px;
        background: $dots_article left bottom repeat-x;
        margin-bottom: 4px;

        h1 {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 20px;
        }

        h3 {
            font-size: 15px;
        }

        .date {
            font-size: 13px;
        }
    }

    .date {
        font-style: italic;
    }
}

.forum_responses {
    .date {
        font-style: italic;
        font-weight: 600;
    }
}

.fullArticle {
    padding: 20px 0;

    p:first-of-type {
        line-height: 20px;

        .first-letter {
            display: block;
            float: left;
            font-size: 95px;
            line-height: 80px;
            margin-top: -5px;
            padding: 0 10px 0 0;
            width: auto;
            height: 80px;
            font-family: $playfair;
            font-weight: bold;
        }
    }
}

.references {
    h4 {
        margin: 0 0 10px;
        font-family: $roboto;
        font-size: 16px;
        font-weight: bold;
    }

    table {
        tr {
            td {
                vertical-align: top;

                &:first-of-type {
                    font-size: 10px;
                    line-height: 16px;
                    font-weight: bold;
                    white-space: nowrap;;
                    width: 15px;
                    text-align: right;
                    padding-right: 5px;
                }

                &:last-of-type {
                    font-size: 10px;
                    line-height: 16px;
                }
            }
        }
    }
}

sidebar {
    .box {
        &.box-tags {
            padding: 12px 0px 0px 0px;
            margin-top: 5px;
            background: $dots_article repeat-x left top;
            h1{
              font-size: 15px;
              margin-bottom: 12px;
            }
            a{
              display: inline-block;
              margin-bottom: 4px;

              padding: 6px 15px;
              background: #e0dfe3;
              font-size: 10px;
              font-family: $opensans;
              font-weight: 300;
              text-transform: uppercase;
              color: $purple-main;
              text-decoration: none;
              &:hover{
                opacity: 0.7;
              }
            }
            hr{
              border: none;
              height: 1px;
              background: $dots_article repeat-x left top;
              margin: 0px;
              margin-top:11px;
            }
        }
    }
}


@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Playfair+Display:400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

/* fonts */
$opensans: 'Open Sans', sans-serif;
$playfair: 'Playfair Display', serif;
$roboto: 'Roboto Slab', serif;

/* colors */
$body-bg: #fff;
$white:#fff;
$purple-main:#202139;
$purple-grey:#8d8992;
$grey-main:#e9e9ec;
$pink-main:#da1f4f;

/* breakpoints */
$big: 960px;
$mid: 680px 960px;
$small: 0 960px;

/*backgrounds*/
$dots_section: url("../img/dots-section-subpages.png");
$dots_article: url("../img/dots-menu_subpages.png");

/* spacings */
$section_padding: 55px;

.inner {
    margin: 0 auto;
    max-width: 960px;
    padding: 0 20px;
    @include breakpoint($big) {
        padding: 0 10px;
    }
    box-sizing: border-box;
    @include clearfix;
}

.right {
    float: right;
}

.left {
    float: left;
}

body {
    background: #e9e9ec;
    color: $purple-main;

    &.homepage {
        background: $purple-main;

        section {
            &.content {
                background: $purple-main;
                color: $white;
            }
        }
    }
}

.hero {
    h1 {
        &.pageTitle {
            margin: 0;
            font-family: $playfair;
            color: $purple-main;
            padding: 0 0 vw-mobile(0);
            line-height: vw-mobile(60);
            font-size: vw-mobile(75);
            @include breakpoint($big) {
                padding: 45px 0;
                line-height: 90px;
                font-size: 110px;
                max-height: 120px;
            }
        }
    }
}

.content {
    @include breakpoint($small) {
        padding: 20px 0 0;
    }
    @include breakpoint($big) {
        padding: $section_padding 0 0;
    }
}

.row {
    sidebar {
        display: block;
        @include breakpoint($small) {
            clear: both;
            padding-top: 30px;
        }
        @include breakpoint($big) {
            float: right;
            width: 267px;
            padding-top: $section_padding;
        }
        font-family: $opensans;

        section {
            header {
                @include clearfix;
                padding-bottom: 20px;
                background: $dots_section bottom left repeat-x;

                h1 {
                    font-size: 18px;
                    float: left;
                    margin: 0;
                    padding: 0;
                }

                .viewmore {
                    color: #8d8992;
                    font-size: 10px;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    line-height: 25px;
                    float: right;
                    height: 18px;
                    position: relative;
                    padding-right: 15px;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 12px;
                        height: 1px;
                        width: 9px;
                        background: #8d8992;
                    }

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 4px;
                        top: 8px;
                        height: 9px;
                        width: 1px;
                        background: #8d8992;
                    }

                    &.less {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .main_container {
        float: left;

        @include breakpoint($big) {
            max-width: 100%;
            width: 100%;
        }
        @include breakpoint($small) {
            clear: both;
            padding-top: $section_padding;
            @include clearfix;
            .btn:last-of-type{
              margin-bottom: 30px;
            }
        }
        @include breakpoint($big) {
            max-width: 625px;
            width: 625px;
            padding-top: $section_padding;
        }

        section {
            header {
                font-family: $opensans;
                @include clearfix;
                padding-bottom: 20px;
                background: $dots_section bottom left repeat-x;

                h1 {
                    font-size: 18px;
                    float: left;
                    margin: 0;
                    padding: 0;
                }

                .viewmore {
                    color: #8d8992;
                    font-size: 10px;
                    font-weight: 200;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    line-height: 25px;
                    float: right;
                    height: 18px;
                    position: relative;
                    padding-right: 15px;
                    text-decoration: none;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 12px;
                        height: 1px;
                        width: 9px;
                        background: #8d8992;
                    }

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        right: 4px;
                        top: 8px;
                        height: 9px;
                        width: 1px;
                        background: #8d8992;
                    }

                    &.less {
                        &:before {
                            display: none;
                        }
                    }

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

.btn {
    display: block;
    background: none;
    border: 1px solid #8d8992;
    padding: 10px 20px;
    text-transform: uppercase;
    color: $purple-main;
    font-size: 11px;
    line-height: 11px;
    font-family: Open Sans,sans-serif;
    cursor: pointer;
    float: left;
    text-decoration: none;

    &:hover {
        background: $purple-main;
        border-color: $purple-main;
        color: #fff;
    }
}

.curtain {
    background: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: none;

    .inner {
        max-width: 100%;
        width: 940px;
        height: auto;
        background: $white;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 80px 0;
        font-family: $opensans;
        font-size: 10px;
        color: $purple-main;
        font-weight: 100;
        letter-spacing: 0.05em;

        .close {
            display: block;
            padding-bottom: 80px;
            cursor: pointer;

            &:hover {
                opacity: 0.5;
            }
        }

        .target {
            width: 540px;
            max-width: 100%;
            height: auto;
            margin: 0 auto;

            iframe {
                margin: 0 auto;
                display: block;
            }
        }
    }
}

.select-nav {
    position: relative;

    .trigger {
        display: block;
        border: 1px solid #8d8992;
        padding: 8px 11px;
        font-size: 10px;
        line-height: 11px;
        font-family: Open Sans,sans-serif;
        cursor: pointer;
        position: relative;

        &:after {
            display: block;
            content: "";
            position: absolute;
            transform: rotate(-90deg);
            background: url("../img/nav-arrow.png") no-repeat center top;
            width: 6px;
            height: 10px;
            background-size: 100% auto;
            right: 10px;
            top: 9px;
        }
    }

    &.active {
        .trigger {
            &:after {
                transform: rotate(90deg);
            }
        }

        .sub {
            display: block;
        }
    }

    .sub {
        border: 1px solid #8d8992;
        max-height: 250px;
        display: none;
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        top: 28px;
        background: $white;

        ul {
            border-bottom: 5px solid $white;
            border-top: 5px solid $white;

            li {
                a {
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    height: 17px;
                    font-size: 10px;
                    line-height: 11px;
                    font-family: $opensans;
                    padding: 3px 11px;
                }

                &:hover {
                    background: $purple-main;

                    a {
                        background: transparent;
                        color: $white;
                    }
                }
            }
        }
    }
}

.search-top {
    font-family: $roboto;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    background: $dots_article left bottom repeat-x;
    margin-bottom: 15px;
    margin-top: -7px;
}

.full_width {
    width: 100%!important;
    max-width: 100%!important;
}

.emulateborder {
    background: $dots_article left bottom repeat-x;
    border: none;
    height: 1px;
    margin: 0;
}

.virtual-issues {
    .wyswig {
        margin-bottom: 20px;
    }
}

.page-btn {
    display: none;
}

table {
    &.paged {
        tr {
            display: none;

            &:nth-of-type(1),
            &:nth-of-type(10),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8),
            &:nth-of-type(9) {
                display: table-row;
            }
        }
    }
}

.toggle {
    &.paged {
        .toggle-item {
            display: none;

            &:nth-of-type(1),
            &:nth-of-type(10),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8),
            &:nth-of-type(9) {
                display: block;
            }
        }
    }
}

/* SCSS */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Playfair+Display:400,400i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Roboto+Slab");
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
html, body {
  margin: 0px; }

body {
  margin: 0; }

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in IE.
 */
figcaption,
figure,
main {
  /* 1 */
  display: block; }

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000; }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 */
details,
menu {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* fonts */
/* colors */
/* breakpoints */
/*backgrounds*/
/* spacings */
.button-container {
  text-align: center; }

fieldset {
  margin: 0 0 28px;
  padding: 0;
  border: none; }

.form-group,
.form-radio {
  position: relative;
  margin-top: 21px;
  margin-bottom: 21px; }

.form-inline > .btn,
.form-inline > .form-group {
  display: inline-block;
  margin-bottom: 0; }

.form-help {
  margin-top: 1.75px;
  margin-left: 1.75px;
  color: #b3b3b3;
  font-size: 11.2px; }
  .checkbox .form-help,
  .form-group .form-help,
  .form-radio .form-help {
    position: absolute;
    width: 100%; }
  .checkbox .form-help {
    position: relative;
    margin-bottom: 14px; }
  .form-radio .form-help {
    padding-top: 3.5px;
    margin-top: -14px; }

.form-group input {
  height: 26.6px; }

.form-group select {
  width: 100%;
  font-size: 14px;
  height: 22.4px;
  padding: 1.75px 1.75px 0.875px;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none; }

.form-group .control-label {
  position: absolute;
  top: 3.5px;
  pointer-events: none;
  padding-left: 1.75px;
  z-index: 1;
  color: #b3b3b3;
  font-size: 14px;
  font-weight: normal;
  transition: all 0.28s ease; }

.form-group .bar {
  position: relative;
  border-bottom: 0.875px solid #999;
  display: block; }
  .form-group .bar::before {
    content: '';
    height: 1.75px;
    width: 0;
    left: 50%;
    bottom: -0.875px;
    position: absolute;
    background: #da1f4f;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2; }

.form-group input,
.form-group textarea {
  display: block;
  background: none;
  padding: 1.75px 1.75px 0.875px;
  font-size: 14px;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: 100%;
  color: transparent;
  transition: all 0.28s ease;
  box-shadow: none; }

.form-group input[type="file"] {
  line-height: 1; }
  .form-group input[type="file"] ~ .bar {
    display: none; }

.form-group input.form-file,
.form-group input.has-value,
.form-group input:focus,
.form-group select,
.form-group textarea.form-file,
.form-group textarea.has-value,
.form-group textarea:focus,
.form-group textarea:valid {
  color: #202139; }
  .form-group input.form-file ~ .control-label,
  .form-group input.has-value ~ .control-label,
  .form-group input:focus ~ .control-label,
  .form-group select ~ .control-label,
  .form-group textarea.form-file ~ .control-label,
  .form-group textarea.has-value ~ .control-label,
  .form-group textarea:focus ~ .control-label,
  .form-group textarea:valid ~ .control-label {
    font-size: 11.2px;
    color: gray;
    top: -14px;
    left: 0; }

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none; }
  .form-group input:focus ~ .control-label,
  .form-group select:focus ~ .control-label,
  .form-group textarea:focus ~ .control-label {
    color: #da1f4f; }
  .form-group input:focus ~ .bar::before,
  .form-group select:focus ~ .bar::before,
  .form-group textarea:focus ~ .bar::before {
    width: 100%;
    left: 0; }

.checkbox label,
.form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 28px;
  text-align: left;
  color: #202139;
  display: block; }

.checkbox input,
.form-radio input {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0; }

.radio {
  margin-bottom: 14px; }
  .radio .helper {
    position: absolute;
    top: -3.5px;
    left: -3.5px;
    cursor: pointer;
    display: block;
    font-size: 14px;
    user-select: none;
    color: #999; }
    .radio .helper::after, .radio .helper::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: 3.5px;
      width: 14px;
      height: 14px;
      transition: transform 0.28s ease;
      border-radius: 50%;
      border: 1.75px solid currentColor; }
    .radio .helper::after {
      transform: scale(0);
      background-color: #da1f4f;
      border-color: #da1f4f; }
  .radio label:hover .helper {
    color: #da1f4f; }
  .radio input:checked ~ .helper::after {
    transform: scale(0.5); }
  .radio input:checked ~ .helper::before {
    color: #da1f4f; }

.checkbox {
  margin-top: 28px;
  margin-bottom: 14px; }
  .checkbox .helper {
    color: #999;
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    z-index: 0;
    border: 1.75px solid currentColor;
    border-radius: 0.875px;
    transition: border-color 0.28s ease; }
    .checkbox .helper::after, .checkbox .helper::before {
      position: absolute;
      height: 0;
      width: 2.8px;
      background-color: #da1f4f;
      display: block;
      transform-origin: left top;
      border-radius: 3.5px;
      content: '';
      transition: opacity 0.28s ease, height 0s linear 0.28s;
      opacity: 0; }
    .checkbox .helper::before {
      top: 9.1px;
      left: 5.32px;
      transform: rotate(-135deg);
      box-shadow: 0 0 0 0.875px #fff; }
    .checkbox .helper::after {
      top: 4.2px;
      left: 0;
      transform: rotate(-45deg); }
  .checkbox label:hover .helper {
    color: #da1f4f; }
  .checkbox input:checked ~ .helper {
    color: #da1f4f; }
    .checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
      opacity: 1;
      transition: height 0.28s ease; }
    .checkbox input:checked ~ .helper::after {
      height: 7px; }
    .checkbox input:checked ~ .helper::before {
      height: 16.8px;
      transition-delay: 0.28s; }

.checkbox + .checkbox,
.radio + .radio {
  margin-top: 14px; }

.has-error.form-group .control-label.control-label,
.has-error .legend.legend {
  color: #d9534f; }

.has-error.checkbox .form-help,
.has-error.checkbox .helper, .has-error.form-group .form-help,
.has-error.form-group .helper, .has-error.form-radio .form-help,
.has-error.form-radio .helper, .has-error.radio .form-help,
.has-error.radio .helper {
  color: #d9534f; }

.has-error .bar::before {
  background: #d9534f;
  left: 0;
  width: 100%; }

.button {
  position: relative;
  background: currentColor;
  border: 1px solid currentColor;
  font-size: 15.4px;
  color: #e54770;
  margin: 28px 0;
  padding: 7px 28px;
  cursor: pointer;
  transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .button span {
    color: #fff;
    position: relative;
    z-index: 1; }
  .button::before {
    content: '';
    position: absolute;
    background: #27060e;
    border: 50vh solid #81122f;
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0); }
  .button:hover {
    color: #da1f4f;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }
  .button:active::before, .button:focus::before {
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
    transform: translate(-50%, -50%) scale(1);
    opacity: 0; }
  .button:focus {
    outline: none; }

.select-group {
  position: relative; }
  .select-group ul.options {
    display: block;
    position: absolute;
    left: 0;
    top: 35px;
    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: top left;
    background-color: white;
    width: 100%;
    background: #fff;
    margin: 0;
    padding: 20px 0;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index: 10;
    line-height: 48px;
    max-height: 480px;
    overflow: auto; }
    .select-group ul.options li {
      display: block;
      width: 100%;
      text-align: center; }
      .select-group ul.options li a {
        text-decoration: none;
        padding: 0 30px;
        width: 100%;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        height: 48px;
        line-height: 48px;
        box-sizing: border-box;
        font-weight: normal;
        font-size: 16px;
        text-align: left;
        text-transform: none;
        color: #333; }
        .select-group ul.options li a:hover {
          background: #eee; }
  .select-group.touched .control-label {
    font-size: 11.2px;
    color: gray;
    top: -14px;
    left: 0; }
  .select-group.touched input {
    color: #202139; }
  .select-group.active ul.options {
    transform: scale(1); }

button {
  display: block;
  background: none;
  border: 1px solid #8d8992;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #da1f4f;
  font-size: 11px;
  line-height: 11px;
  font-family: "Open Sans", sans-serif;
  cursor: pointer; }
  button:hover {
    color: #8d8992;
    background: #da1f4f;
    border-color: #da1f4f; }

.inner {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 20px;
  box-sizing: border-box; }
  @media (min-width: 960px) {
    .inner {
      padding: 0 10px; } }
  .inner:after {
    content: "";
    display: table;
    clear: both; }

.right {
  float: right; }

.left {
  float: left; }

body {
  background: #e9e9ec;
  color: #202139; }
  body.homepage {
    background: #202139; }
    body.homepage section.content {
      background: #202139;
      color: #fff; }

.hero h1.pageTitle {
  margin: 0;
  font-family: "Playfair Display", serif;
  color: #202139;
  padding: 0 0 0vw;
  line-height: 9.375vw;
  font-size: 11.71875vw; }
  @media (min-width: 960px) {
    .hero h1.pageTitle {
      padding: 45px 0;
      line-height: 90px;
      font-size: 110px;
      max-height: 120px; } }

@media (min-width: 0) and (max-width: 960px) {
  .content {
    padding: 20px 0 0; } }

@media (min-width: 960px) {
  .content {
    padding: 55px 0 0; } }

.row sidebar {
  display: block;
  font-family: "Open Sans", sans-serif; }
  @media (min-width: 0) and (max-width: 960px) {
    .row sidebar {
      clear: both;
      padding-top: 30px; } }
  @media (min-width: 960px) {
    .row sidebar {
      float: right;
      width: 267px;
      padding-top: 55px; } }
  .row sidebar section header {
    padding-bottom: 20px;
    background: url("../img/dots-section-subpages.png") bottom left repeat-x; }
    .row sidebar section header:after {
      content: "";
      display: table;
      clear: both; }
    .row sidebar section header h1 {
      font-size: 18px;
      float: left;
      margin: 0;
      padding: 0; }
    .row sidebar section header .viewmore {
      color: #8d8992;
      font-size: 10px;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 25px;
      float: right;
      height: 18px;
      position: relative;
      padding-right: 15px; }
      .row sidebar section header .viewmore:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 12px;
        height: 1px;
        width: 9px;
        background: #8d8992; }
      .row sidebar section header .viewmore:before {
        content: "";
        display: block;
        position: absolute;
        right: 4px;
        top: 8px;
        height: 9px;
        width: 1px;
        background: #8d8992; }
      .row sidebar section header .viewmore.less:before {
        display: none; }

.row .main_container {
  float: left; }
  @media (min-width: 960px) {
    .row .main_container {
      max-width: 100%;
      width: 100%; } }
  @media (min-width: 0) and (max-width: 960px) {
    .row .main_container {
      clear: both;
      padding-top: 55px; }
      .row .main_container:after {
        content: "";
        display: table;
        clear: both; }
      .row .main_container .btn:last-of-type {
        margin-bottom: 30px; } }
  @media (min-width: 960px) {
    .row .main_container {
      max-width: 625px;
      width: 625px;
      padding-top: 55px; } }
  .row .main_container section header {
    font-family: "Open Sans", sans-serif;
    padding-bottom: 20px;
    background: url("../img/dots-section-subpages.png") bottom left repeat-x; }
    .row .main_container section header:after {
      content: "";
      display: table;
      clear: both; }
    .row .main_container section header h1 {
      font-size: 18px;
      float: left;
      margin: 0;
      padding: 0; }
    .row .main_container section header .viewmore {
      color: #8d8992;
      font-size: 10px;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 25px;
      float: right;
      height: 18px;
      position: relative;
      padding-right: 15px;
      text-decoration: none; }
      .row .main_container section header .viewmore:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 12px;
        height: 1px;
        width: 9px;
        background: #8d8992; }
      .row .main_container section header .viewmore:before {
        content: "";
        display: block;
        position: absolute;
        right: 4px;
        top: 8px;
        height: 9px;
        width: 1px;
        background: #8d8992; }
      .row .main_container section header .viewmore.less:before {
        display: none; }
      .row .main_container section header .viewmore:hover {
        opacity: 0.6; }

.btn {
  display: block;
  background: none;
  border: 1px solid #8d8992;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #202139;
  font-size: 11px;
  line-height: 11px;
  font-family: Open Sans,sans-serif;
  cursor: pointer;
  float: left;
  text-decoration: none; }
  .btn:hover {
    background: #202139;
    border-color: #202139;
    color: #fff; }

.curtain {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: none; }
  .curtain .inner {
    max-width: 100%;
    width: 940px;
    height: auto;
    background: #fff;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 80px 0;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    color: #202139;
    font-weight: 100;
    letter-spacing: 0.05em; }
    .curtain .inner .close {
      display: block;
      padding-bottom: 80px;
      cursor: pointer; }
      .curtain .inner .close:hover {
        opacity: 0.5; }
    .curtain .inner .target {
      width: 540px;
      max-width: 100%;
      height: auto;
      margin: 0 auto; }
      .curtain .inner .target iframe {
        margin: 0 auto;
        display: block; }

.select-nav {
  position: relative; }
  .select-nav .trigger {
    display: block;
    border: 1px solid #8d8992;
    padding: 8px 11px;
    font-size: 10px;
    line-height: 11px;
    font-family: Open Sans,sans-serif;
    cursor: pointer;
    position: relative; }
    .select-nav .trigger:after {
      display: block;
      content: "";
      position: absolute;
      transform: rotate(-90deg);
      background: url("../img/nav-arrow.png") no-repeat center top;
      width: 6px;
      height: 10px;
      background-size: 100% auto;
      right: 10px;
      top: 9px; }
  .select-nav.active .trigger:after {
    transform: rotate(90deg); }
  .select-nav.active .sub {
    display: block; }
  .select-nav .sub {
    border: 1px solid #8d8992;
    max-height: 250px;
    display: none;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    top: 28px;
    background: #fff; }
    .select-nav .sub ul {
      border-bottom: 5px solid #fff;
      border-top: 5px solid #fff; }
      .select-nav .sub ul li a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        height: 17px;
        font-size: 10px;
        line-height: 11px;
        font-family: "Open Sans", sans-serif;
        padding: 3px 11px; }
      .select-nav .sub ul li:hover {
        background: #202139; }
        .select-nav .sub ul li:hover a {
          background: transparent;
          color: #fff; }

.search-top {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
  background: url("../img/dots-menu_subpages.png") left bottom repeat-x;
  margin-bottom: 15px;
  margin-top: -7px; }

.full_width {
  width: 100% !important;
  max-width: 100% !important; }

.emulateborder {
  background: url("../img/dots-menu_subpages.png") left bottom repeat-x;
  border: none;
  height: 1px;
  margin: 0; }

.virtual-issues .wyswig {
  margin-bottom: 20px; }

.page-btn {
  display: none; }

table.paged tr {
  display: none; }
  table.paged tr:nth-of-type(1), table.paged tr:nth-of-type(10), table.paged tr:nth-of-type(2), table.paged tr:nth-of-type(3), table.paged tr:nth-of-type(4), table.paged tr:nth-of-type(5), table.paged tr:nth-of-type(6), table.paged tr:nth-of-type(7), table.paged tr:nth-of-type(8), table.paged tr:nth-of-type(9) {
    display: table-row; }

.toggle.paged .toggle-item {
  display: none; }
  .toggle.paged .toggle-item:nth-of-type(1), .toggle.paged .toggle-item:nth-of-type(10), .toggle.paged .toggle-item:nth-of-type(2), .toggle.paged .toggle-item:nth-of-type(3), .toggle.paged .toggle-item:nth-of-type(4), .toggle.paged .toggle-item:nth-of-type(5), .toggle.paged .toggle-item:nth-of-type(6), .toggle.paged .toggle-item:nth-of-type(7), .toggle.paged .toggle-item:nth-of-type(8), .toggle.paged .toggle-item:nth-of-type(9) {
    display: block; }

article a {
  color: inherit;
  text-decoration: none; }
  article a:hover h1 {
    text-decoration: underline; }

article .category {
  color: #da1f4f;
  text-transform: uppercase; }

article .postdate {
  font-style: italic; }

article h4 {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  margin-bottom: 5px; }

article h1 {
  font-family: "Roboto Slab", serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  color: #202139;
  margin: 0;
  padding: 0;
  margin-bottom: 5px; }

article p {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px; }

article h3 {
  font-size: 12px;
  color: #202139;
  margin: 0;
  padding: 0;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: normal; }

article.huge h4 {
  font-size: 12px;
  margin-bottom: 10px; }
  @media (min-width: 960px) {
    article.huge h4 {
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 20px; } }

article.huge h1 {
  font-size: 28px;
  line-height: 30px;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  letter-spacing: -0.005em;
  margin-bottom: 20px; }
  @media (min-width: 960px) {
    article.huge h1 {
      font-size: 73px;
      line-height: 75px;
      margin-bottom: 25px; } }

article.huge p {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 20px;
  font-family: "Playfair Display", serif;
  font-weight: normal;
  letter-spacing: -0.005em;
  max-width: 640px; }
  @media (min-width: 960px) {
    article.huge p {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 25px; } }

article.huge h3 {
  font-size: 13px; }
  @media (min-width: 960px) {
    article.huge h3 {
      font-size: 15px; } }

article.tweet h1 {
  font-size: 11px;
  font-family: "Open Sans", sans-serif;
  line-height: 16px;
  padding-left: 16px;
  background: url("../img/ico_tweet_dark.png") no-repeat left center;
  padding-bottom: 0;
  margin-bottom: 0; }

article.person h1,
article.person h2,
article.person h3 {
  font-size: 11px;
  line-height: 14px;
  margin: 0;
  padding: 0;
  color: #202139;
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

article.person h1 {
  font-family: "Roboto Slab", serif;
  font-weight: bold; }

article.person hr {
  display: block;
  border: none;
  width: 20px;
  height: 1px;
  padding: 0;
  margin: 10px 0;
  background: url("../img/dots-menu_subpages.png") repeat-x left center; }

article.person p {
  font-size: 11px;
  line-height: 16px; }

.article_grid {
  padding-bottom: 55px; }
  .article_grid:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 960px) {
    .article_grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .article_grid article {
    box-sizing: border-box;
    padding: 20px 20px 20px 0;
    margin-right: 23px;
    background: url("../img/dots-menu_subpages.png") bottom left repeat-x; }
    @media (min-width: 960px) {
      .article_grid article {
        width: 300px; } }
    .article_grid article:nth-child(even) {
      margin-right: 0; }

.article_list {
  padding: 22px 0 23px;
  background: url("../img/dots-menu_subpages.png") bottom left repeat-x; }
  .article_list h4 {
    color: #da1f4f;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    margin-bottom: 10px; }
  .article_list article {
    margin-bottom: 25px; }
    .article_list article:last-of-type {
      margin-bottom: 0; }

sidebar article {
  padding: 20px 0;
  width: 100%;
  background: url("../img/dots-menu_subpages.png") bottom left repeat-x; }
  sidebar article p {
    color: #8d8992; }

header.top {
  background: #202139;
  color: #fff;
  border-bottom: 1px solid #202139;
  position: relative;
  z-index: 10;
  transition: border 0.5s ease-out; }
  @media (min-width: 960px) {
    header.top {
      padding: 32px 0 22px; } }
  @media (min-width: 0) and (max-width: 960px) {
    header.top {
      padding: 20px 0; } }
  @media (min-width: 0) and (max-width: 960px) {
    header.top .inner .logo img {
      width: 165px; } }
  header.top .inner nav a {
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.01em;
    position: relative;
    line-height: 14px;
    height: 19px;
    display: block;
    float: left;
    padding-top: 2px;
    box-sizing: border-box;
    text-indent: -9999px; }
    @media (min-width: 0) and (max-width: 960px) {
      header.top .inner nav a {
        width: 20px;
        margin-left: 20px; } }
    @media (min-width: 960px) {
      header.top .inner nav a {
        margin-left: 77px;
        padding-left: 30px;
        text-indent: 0;
        margin-top: 10px; } }
    header.top .inner nav a:before {
      display: block;
      width: 20px;
      height: 20px;
      background-position: center right !important;
      position: absolute;
      content: "";
      left: 0;
      top: 0; }
      @media (min-width: 960px) {
        header.top .inner nav a:before {
          width: 30px;
          height: 30px; } }
    header.top .inner nav a.toggle_search {
      background: url("../img/search.png") no-repeat left center; }
    header.top .inner nav a.toggle_menu {
      background: url("../img/menu.png") no-repeat left center; }

nav.main {
  box-shadow: 0 200px 100px 50px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  background: #202139;
  font-family: "Open Sans", sans-serif;
  top: 60px;
  min-height: 100vh;
  margin-top: -300vh;
  padding: 0 20px 20px 0;
  transition: margin-top 0.5s ease-out;
  z-index: 1000; }
  @media (min-width: 960px) {
    nav.main {
      margin-top: -300vh;
      top: 83px;
      padding: 55px 0;
      min-height: 0; } }
  nav.main ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    nav.main ul:after {
      content: "";
      display: table;
      clear: both; }
    nav.main ul li {
      width: 100%;
      margin-bottom: 10px; }
      nav.main ul li:nth-of-type(3n+3) {
        margin-right: 0; }
      @media (min-width: 960px) {
        nav.main ul li {
          width: 300px;
          float: left;
          margin-right: 20px;
          margin-bottom: 50px; } }
      nav.main ul li a {
        color: #fff;
        text-decoration: none;
        display: block;
        padding: 10px 0;
        background: url("../img/dots-menu.png") repeat-x left bottom;
        box-sizing: border-box;
        position: relative;
        cursor: pointer; }
        @media (min-width: 960px) {
          nav.main ul li a {
            padding: 15px 0 30px; } }
        nav.main ul li a:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 0px;
          border-bottom: 1px dotted #da1f4f;
          width: 0;
          transition: width 0.2s ease-out; }
        nav.main ul li a:hover {
          color: #da1f4f; }
          nav.main ul li a:hover:after {
            width: 100%; }
        nav.main ul li a strong {
          display: block;
          font-family: "Roboto Slab", serif;
          font-weight: normal;
          font-size: 18px;
          line-height: 18px;
          padding-bottom: 8px; }
        nav.main ul li a span {
          font-size: 11px;
          color: #8d8992;
          line-height: 11px;
          display: block; }

body.menu nav.main {
  margin-top: 0; }

body.menu header.top a.toggle_menu {
  background: url("../img/menu-close.png") no-repeat left center;
  background-size: auto auto; }

body.menu header.top a.toggle_search {
  display: none; }

body.search .search_area {
  opacity: 1;
  visibility: visible; }
  @media (min-width: 960px) {
    body.search .search_area {
      padding: 32px 0 22px; } }
  @media (min-width: 0) and (max-width: 960px) {
    body.search .search_area {
      padding: 20px 0; } }

.homepage:not(.menu) .search_area,
.homepage:not(.menu) header.top {
  border-bottom: 1px solid #da1f4f; }

.homepage:not(.menu) .search_area .toggle_search {
  color: #fff; }

.search_area {
  color: #fff;
  border-bottom: 1px solid #202139;
  position: relative;
  z-index: 0;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  opacity: 0;
  padding: 0px;
  visibility: hidden; }
  .search_area input {
    background: transparent;
    border: none;
    font-family: "Open Sans", sans-serif;
    color: #8d8992;
    padding: 0;
    margin: 0;
    font-size: 24px;
    height: 30px;
    outline: none; }
  .search_area .toggle_search {
    float: right;
    font-size: 10px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0.05em;
    margin-top: 20px;
    color: #8d8992; }
  .search_area ::-webkit-input-placeholder {
    color: rgba(122, 122, 122, 0.2); }
  .search_area :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(122, 122, 122, 0.2); }
  .search_area ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(122, 122, 122, 0.2); }
  .search_area :-ms-input-placeholder {
    color: rgba(122, 122, 122, 0.2); }

sidebar .box {
  margin-top: 30px;
  width: 100%;
  padding: 23px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.02); }
  sidebar .box h1 {
    font-size: 16px;
    margin: 0;
    padding: 0;
    font-family: "Roboto Slab", serif;
    margin-bottom: 10px; }
  sidebar .box p {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #8d8992;
    margin-bottom: 10px; }
  sidebar .box.white {
    background: #fff;
    color: #202139;
    margin-top: 10px; }
    sidebar .box.white:after {
      content: "";
      display: table;
      clear: both; }
    sidebar .box.white h1 {
      font-size: 15px;
      margin-bottom: 10px; }
    sidebar .box.white p {
      color: #202139;
      font-size: 11px;
      margin-bottom: 13px; }
    sidebar .box.white .btn {
      font-size: 10px;
      padding: 8px 20px; }
    sidebar .box.white.first {
      margin-top: -3px; }
    sidebar .box.white.styleguide {
      margin-top: 25px; }
  sidebar .box .form-group {
    margin-top: 20px;
    margin-bottom: 15px; }
    sidebar .box .form-group .control-label {
      font-size: 11px;
      color: #fff !important;
      top: -8px; }
    sidebar .box .form-group input {
      line-height: 1em;
      font-size: 12px;
      color: #8d8992; }
    sidebar .box .form-group .bar {
      border-bottom-color: #8d8992; }

sidebar nav {
  margin: 0;
  padding: 0; }
  sidebar nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    sidebar nav ul li {
      display: block; }
      sidebar nav ul li:after {
        content: "";
        display: table;
        clear: both; }
      sidebar nav ul li a {
        color: #202139;
        font-family: "Roboto Slab", serif;
        font-size: 12px;
        display: block;
        line-height: 20px;
        height: 20px;
        float: left;
        text-decoration: none; }
      sidebar nav ul li.active {
        background: url("../img/dots-menu_subpages.png") repeat-x center center; }
        sidebar nav ul li.active a {
          background: #e9e9ec;
          padding-right: 15px;
          font-weight: bold;
          color: #202139 !important; }
      sidebar nav ul li:hover a {
        color: #da1f4f; }

sidebar .social_print {
  padding: 9px 0 0; }
  sidebar .social_print:after {
    content: "";
    display: table;
    clear: both; }
  sidebar .social_print nav.share {
    float: left; }
    sidebar .social_print nav.share a {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 3px;
      overflow: hidden;
      background: url("../img/social.png") no-repeat left top;
      text-indent: -99999px; }
      sidebar .social_print nav.share a:hover {
        opacity: 0.7; }
      sidebar .social_print nav.share a.email {
        background-position: right top;
        width: 27px; }
      sidebar .social_print nav.share a.twitter {
        background-position: -61px top;
        width: 27px; }
      sidebar .social_print nav.share a.linkedin {
        background-position: -30px top; }
  sidebar .social_print .print {
    display: block;
    float: right;
    width: 19px;
    height: 19px;
    background: url("../img/print.png") no-repeat left center;
    overflow: hidden;
    text-indent: -9999px;
    cursor: pointer; }
    sidebar .social_print .print:hover {
      background: url("../img/print.png") no-repeat right center; }

.mc4wp-form-submitted .ls mc4wp-form-fields {
  display: none; }

.mc4wp-alert {
  margin-top: 20px; }

.btn_sidebar_info {
  float: none;
  margin-top: 20px;
  color: #fff;
  text-align: center; }
  .btn_sidebar_info:hover {
    color: #202139;
    border-color: #fff;
    background: #fff; }

.mc4wp-form-fields button {
  color: #fff; }
  .mc4wp-form-fields button:hover {
    background: #fff;
    color: #202139; }

footer {
  font-family: "Open Sans", sans-serif;
  font-size: 10px;
  padding-top: 95px; }
  footer .apps {
    color: #202139;
    float: right;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    height: 45px; }
    @media (min-width: 0) and (max-width: 960px) {
      footer .apps {
        height: 40px; } }
    footer .apps .itunes {
      padding-right: 20px;
      background: url("../img/appstore.svg") no-repeat right top;
      background-size: 96px 29px;
      cursor: pointer;
      width: 96px;
      height: 29px;
      display: inline-block;
      float: right; }
      @media (min-width: 0) and (max-width: 960px) {
        footer .apps .itunes {
          width: 80px;
          height: 24px;
          background-size: 80px 24px;
          padding-right: 10px; } }
    footer .apps .googleplay {
      padding-right: 20px;
      background: url("../img/googleplay.svg") no-repeat right top;
      background-size: 102px 29px;
      cursor: pointer;
      width: 102px;
      height: 29px;
      display: inline-block;
      float: right; }
      @media (min-width: 0) and (max-width: 960px) {
        footer .apps .googleplay {
          width: 80px;
          height: 24px;
          background-size: 80px 24px;
          padding-right: 10px; } }
    footer .apps p {
      line-height: 46px;
      margin: 0;
      text-decoration: none;
      float: right;
      display: inline-block; }
      @media (min-width: 0) and (max-width: 960px) {
        footer .apps p {
          visibility: hidden;
          width: 1px;
          height: 46px; } }
  footer nav.social {
    max-width: 60px;
    float: left;
    margin-top: 8px; }
    @media (min-width: 960px) {
      footer nav.social {
        text-align: right; } }
    footer nav.social:after {
      content: "";
      display: table;
      clear: both; }
    footer nav.social a {
      display: block;
      float: left;
      margin-left: 0;
      margin-right: 3px;
      width: 20px;
      height: 20px;
      background: #202139;
      text-indent: -9999px;
      overflow: hidden;
      background-size: 20px 20px; }
      @media (min-width: 960px) {
        footer nav.social a {
          float: left;
          margin-right: 0;
          margin-left: 3px; } }
      footer nav.social a.facebook {
        background: url("../img/ico_facebook-dark.png") no-repeat center center; }
      footer nav.social a.twitter {
        background: url("../img/ico_twitter-dark.png") no-repeat center center; }
  footer .end {
    clear: both;
    margin-top: 15px;
    border-top: 3px solid #202139;
    padding-top: 15px;
    padding-bottom: 45px;
    font-weight: 200;
    letter-spacing: 0.01em; }
    footer .end a {
      display: block;
      text-decoration: none;
      color: #202139; }
    footer .end p {
      padding: 0;
      margin: 0;
      color: #202139;
      max-width: 70%;
      line-height: 1.3; }
    footer .end:after {
      content: "";
      display: table;
      clear: both; }

.homepage footer {
  background: #202139; }
  .homepage footer nav.social a.facebook {
    background: url("../img/ico_facebook-light.png") no-repeat center center; }
  .homepage footer nav.social a.twitter {
    background: url("../img/ico_twitter-light.png") no-repeat center center; }
  .homepage footer .end {
    border-top-color: #da1f4f; }
    .homepage footer .end a,
    .homepage footer .end p {
      color: #8d8992; }
  .homepage footer .apps p {
    color: #8d8992; }

.homepage sidebar .box form .form-group i {
  border-color: #444356; }

.homepage sidebar .box form button {
  border-color: #444356; }

.homepage .row sidebar section header {
  background-image: url("../img/dots-section-homepage.png"); }

.homepage .row .main_container section header {
  background-image: url("../img/dots-section-homepage.png"); }

.homepage .article_grid article {
  background: url("../img/dots-menu.png") bottom left repeat-x; }

.homepage sidebar article {
  background: url("../img/dots-menu.png") bottom left repeat-x; }
  .homepage sidebar article a {
    text-decoration: underline; }

.homepage article h1 {
  color: #fff; }

.homepage article h3 {
  color: #8d8992; }

.main_container .single-content {
  padding-top: 36px;
  background: url("../img/dots-section-subpages.png") repeat-x left top; }

sidebar.single-content {
  padding-top: 36px;
  background: url("../img/dots-section-subpages.png") repeat-x left top;
  margin-top: 55px; }

.wyswig p {
  font-size: 13px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
  padding: 0 0 1.5em;
  margin: 0; }

.wyswig h1,
.wyswig h2,
.wyswig h3,
.wyswig h4,
.wyswig h5,
.wyswig h6 {
  font-family: "Roboto Slab", serif;
  margin: 1em 0 0.2em;
  padding: 0 0 16px; }
  .wyswig h1.margin-top-0,
  .wyswig h2.margin-top-0,
  .wyswig h3.margin-top-0,
  .wyswig h4.margin-top-0,
  .wyswig h5.margin-top-0,
  .wyswig h6.margin-top-0 {
    margin-top: 0; }

.wyswig a {
  color: inherit; }

.wyswig h1 {
  font-size: 20px; }

.wyswig h2 {
  font-size: 18px; }

.wyswig h3 {
  font-size: 16px; }

.wyswig sup {
  font-weight: bold; }
  .wyswig sup a {
    text-decoration: none; }
    .wyswig sup a:hover {
      color: #da1f4f; }

.wyswig ol,
.wyswig ul {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 20px;
  padding: 0 0 1.5em 15px;
  font-weight: normal;
  margin-bottom: 0; }
  .wyswig ol li,
  .wyswig ul li {
    margin-bottom: 10px; }
    .wyswig ol li ol,
    .wyswig ul li ol {
      list-style-type: lower-alpha;
      font-weight: normal;
      font-style: italic;
      padding: 20px 0 10px 20px; }
      .wyswig ol li ol span,
      .wyswig ul li ol span {
        font-style: normal; }

.toggle {
  margin-bottom: 50px; }
  .toggle header {
    padding-bottom: 15px;
    padding-top: 30px;
    background: url("../img/dots-menu_subpages.png") bottom left repeat-x; }
    .toggle header:after {
      content: "";
      display: table;
      clear: both; }
    .toggle header h1 {
      font-size: 18px;
      float: left;
      margin: 0;
      padding: 0;
      font-family: "Roboto Slab", serif; }
    .toggle header .viewmore {
      color: #202139;
      font-size: 10px;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 25px;
      float: right;
      height: 18px;
      position: relative;
      padding-right: 15px;
      cursor: pointer; }
      .toggle header .viewmore:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 12px;
        height: 1px;
        width: 9px;
        background: #202139; }
      .toggle header .viewmore:before {
        content: "";
        display: block;
        position: absolute;
        right: 4px;
        top: 8px;
        height: 9px;
        width: 1px;
        background: #202139; }
      .toggle header .viewmore .less {
        display: none; }
      .toggle header .viewmore .more {
        display: block; }
      .toggle header .viewmore.less:before {
        display: none; }
      .toggle header .viewmore.less .less {
        display: block; }
      .toggle header .viewmore.less .more {
        display: none; }
      .toggle header .viewmore:hover {
        color: #da1f4f; }
        .toggle header .viewmore:hover:after, .toggle header .viewmore:hover:before {
          background: #da1f4f !important; }
  .toggle .content {
    background: url("../img/dots-menu_subpages.png") bottom left repeat-x;
    max-height: 0;
    padding: 0;
    transition: all 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
    visibility: hidden; }
    .toggle .content table {
      width: 100%;
      font-size: 13px;
      line-height: 20px;
      font-family: "Open Sans", sans-serif;
      display: none; }
      .toggle .content table td {
        vertical-align: top; }
        .toggle .content table td.date {
          font-style: italic;
          width: 60px;
          font-weight: bold;
          font-size: 11px; }
        .toggle .content table td.place {
          width: 217px; }
  .toggle .toggle-item.opened .content {
    max-height: 1000000px;
    padding: 20px 0 31px;
    opacity: 1;
    visibility: visible; }
    .toggle .toggle-item.opened .content table {
      display: table; }

.lecture {
  border: 1px solid #5c5d6e;
  margin-top: 20px;
  margin-bottom: 50px; }
  .lecture header {
    background: #5c5d6e;
    color: #fff;
    padding: 18px 25px;
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px; }
    .lecture header:after {
      content: "";
      display: table;
      clear: both; }
    .lecture header span {
      display: block;
      float: right;
      height: 20px;
      font-size: 10px;
      line-height: 18px;
      box-sizing: border-box;
      border: 1px solid #8d8e9a;
      font-family: "Open Sans", sans-serif;
      text-transform: uppercase;
      padding: 0 12px;
      font-weight: 600; }
      @media (min-width: 0) and (max-width: 960px) {
        .lecture header span {
          clear: both;
          float: left;
          margin-top: 5px; } }
  .lecture .lecture-content {
    padding: 25px; }
    .lecture .lecture-content h1 {
      margin: 0;
      padding: 0;
      font-family: "Roboto Slab", serif;
      font-size: 16px;
      font-weight: 600; }
    .lecture .lecture-content h2 {
      margin: 0;
      padding: 0;
      font-family: "Playfair Display", serif;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
      padding: 10px 0; }
    .lecture .lecture-content p {
      font-family: "Open Sans", sans-serif;
      line-height: 16px;
      font-size: 11px;
      padding: 10px 0 0;
      margin: 0; }
  .lecture .lecture-footer {
    padding: 20px 0;
    margin: 0 25px;
    background: url("../img/dots-menu_subpages.png") repeat-x left top; }
    .lecture .lecture-footer div {
      display: inline-block;
      margin-right: 30px;
      font-size: 11px;
      font-family: "Open Sans", sans-serif;
      color: #8d8992; }
      .lecture .lecture-footer div strong {
        margin-right: 2px;
        color: #202139; }

.lectures {
  width: 100%;
  background: url("../img/dots-menu_subpages.png") repeat-x left top;
  margin-bottom: 50px; }
  .lectures tr {
    background: url("../img/dots-menu_subpages.png") repeat-x left bottom; }
    .lectures tr.more td:nth-of-type(2) .details {
      display: block !important; }
    .lectures tr.more td:nth-of-type(3) .viewmore:before {
      display: none; }
    .lectures tr.more td:nth-of-type(3) .viewmore .less {
      display: block; }
    .lectures tr.more td:nth-of-type(3) .viewmore .more {
      display: none; }
  .lectures td {
    vertical-align: top;
    padding: 25px 0;
    border-spacing: 0; }
    .lectures td:first-of-type {
      font-size: 11px;
      font-family: "Open Sans", sans-serif;
      font-style: italic;
      font-weight: 700;
      line-height: 22px;
      padding-right: 25px; }
    .lectures td:nth-of-type(2) {
      font-family: "Roboto Slab", serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      max-width: 430px; }
      .lectures td:nth-of-type(2) span.author {
        display: block;
        font-size: 12px;
        font-family: "Playfair Display", serif;
        font-style: italic;
        font-weight: normal;
        padding-top: 5px; }
      .lectures td:nth-of-type(2) .details {
        padding-top: 15px;
        display: none; }
        .lectures td:nth-of-type(2) .details p {
          font-weight: normal; }
    .lectures td:nth-of-type(3) {
      text-align: right;
      width: 140px; }
      .lectures td:nth-of-type(3) i {
        display: inline-block;
        width: 17px;
        height: 14px;
        background: url("../img/speaker.png") no-repeat center top;
        background-size: 100% auto;
        cursor: pointer; }
        .lectures td:nth-of-type(3) i:hover {
          background-position: center bottom; }
      .lectures td:nth-of-type(3) a.video + .viewmore {
        margin-top: 10px !important; }
      .lectures td:nth-of-type(3) .viewmore {
        color: #202139;
        font-size: 10px;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        line-height: 25px;
        display: block;
        height: 18px;
        margin-top: 0px;
        position: relative;
        padding-right: 15px;
        cursor: pointer; }
        .lectures td:nth-of-type(3) .viewmore:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: 12px;
          height: 1px;
          width: 9px;
          background: #202139; }
        .lectures td:nth-of-type(3) .viewmore:before {
          content: "";
          display: block;
          position: absolute;
          right: 4px;
          top: 8px;
          height: 9px;
          width: 1px;
          background: #202139; }
        .lectures td:nth-of-type(3) .viewmore .less {
          display: none; }
        .lectures td:nth-of-type(3) .viewmore .more {
          display: block; }
        .lectures td:nth-of-type(3) .viewmore.less:before {
          display: none; }
        .lectures td:nth-of-type(3) .viewmore.less .less {
          display: block; }
        .lectures td:nth-of-type(3) .viewmore.less .more {
          display: none; }
        .lectures td:nth-of-type(3) .viewmore:hover {
          color: #da1f4f; }
          .lectures td:nth-of-type(3) .viewmore:hover:after, .lectures td:nth-of-type(3) .viewmore:hover:before {
            background: #da1f4f !important; }
    .lectures td a {
      text-decoration: none; }
      .lectures td a:hover {
        text-decoration: underline; }

.btn-fullarticle {
  margin-bottom: 70px;
  margin-top: -10px; }

.forum_responses {
  clear: both; }

.references table {
  word-break: break-all; }

section.review_page {
  margin-bottom: 70px; }
  section.review_page header .review-nav {
    float: right; }
    section.review_page header .review-nav a {
      color: #202139;
      font-size: 10px;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      line-height: 25px;
      height: 18px;
      position: relative;
      text-decoration: none; }
      section.review_page header .review-nav a:hover {
        color: #da1f4f; }
      section.review_page header .review-nav a:before {
        width: 4px;
        height: 6px;
        position: absolute;
        left: 0;
        top: 5px;
        content: "";
        display: block;
        background: url("../img/nav-arrow.png") no-repeat left top;
        background-size: 100% auto; }
      section.review_page header .review-nav a.next {
        padding-right: 10px;
        margin-left: 10px; }
        section.review_page header .review-nav a.next:before {
          left: auto;
          right: 0;
          transform: rotate(180deg); }
      section.review_page header .review-nav a:hover {
        color: #da1f4f; }
        section.review_page header .review-nav a:hover:before {
          background-position: left bottom; }
      section.review_page header .review-nav a.prev {
        padding-left: 10px; }

sidebar .the_review_summary {
  background: #5c5d6e;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 35px !important; }
  sidebar .the_review_summary h1 {
    color: #fff; }
  sidebar .the_review_summary table {
    font-size: 11px;
    font-family: "Open Sans", sans-serif; }
    sidebar .the_review_summary table th {
      text-align: left;
      font-weight: bold;
      padding-right: 20px; }

.abstract {
  padding: 20px 0px 0px 0px; }

i.ico.href {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../img/href.png) no-repeat center center;
  margin-left: 5px;
  transform: translateY(1px); }

.contact-row {
  padding-bottom: 55px; }
  .contact-row:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 960px) {
    .contact-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .contact-row .col {
    box-sizing: border-box;
    padding-bottom: 30px;
    color: #202139; }
    @media (min-width: 960px) {
      .contact-row .col {
        width: 300px;
        border: 1px solid #8d8992;
        padding: 20px 25px 20px 25px; } }
    .contact-row .col:nth-child(even) {
      margin-right: 0; }
    .contact-row .col p {
      line-height: 20px;
      margin: 0px;
      padding: 0px;
      font-family: "Open Sans", sans-serif;
      font-size: 13px; }
      .contact-row .col p a {
        line-height: 20px;
        margin: 0px;
        padding: 0px;
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        color: inherit; }
    .contact-row .col h1 {
      font-size: 13px;
      line-height: 20px;
      margin: 10px 0px 0px 0px;
      padding: 0px; }

.forum-top-article article {
  padding-bottom: 30px;
  background: url("../img/dots-menu_subpages.png") left bottom repeat-x;
  margin-bottom: 4px; }
  .forum-top-article article h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px; }
  .forum-top-article article p {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 20px; }
  .forum-top-article article h3 {
    font-size: 15px; }
  .forum-top-article article .date {
    font-size: 13px; }

.forum-top-article .date {
  font-style: italic; }

.forum_responses .date {
  font-style: italic;
  font-weight: 600; }

.fullArticle {
  padding: 20px 0; }
  .fullArticle p:first-of-type {
    line-height: 20px; }
    .fullArticle p:first-of-type .first-letter {
      display: block;
      float: left;
      font-size: 95px;
      line-height: 80px;
      margin-top: -5px;
      padding: 0 10px 0 0;
      width: auto;
      height: 80px;
      font-family: "Playfair Display", serif;
      font-weight: bold; }

.references h4 {
  margin: 0 0 10px;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: bold; }

.references table tr td {
  vertical-align: top; }
  .references table tr td:first-of-type {
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;
    white-space: nowrap;
    width: 15px;
    text-align: right;
    padding-right: 5px; }
  .references table tr td:last-of-type {
    font-size: 10px;
    line-height: 16px; }

sidebar .box.box-tags {
  padding: 12px 0px 0px 0px;
  margin-top: 5px;
  background: url("../img/dots-menu_subpages.png") repeat-x left top; }
  sidebar .box.box-tags h1 {
    font-size: 15px;
    margin-bottom: 12px; }
  sidebar .box.box-tags a {
    display: inline-block;
    margin-bottom: 4px;
    padding: 6px 15px;
    background: #e0dfe3;
    font-size: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    color: #202139;
    text-decoration: none; }
    sidebar .box.box-tags a:hover {
      opacity: 0.7; }
  sidebar .box.box-tags hr {
    border: none;
    height: 1px;
    background: url("../img/dots-menu_subpages.png") repeat-x left top;
    margin: 0px;
    margin-top: 11px; }

.loading {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000; }

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }

.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 48vh auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: ''; }

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.loader:after {
  left: 1.5em; }

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.form {
  font-family: "Open Sans", sans-serif; }
  .form .submitNote {
    clear: both;
    font-size: 11px;
    padding-top: 40px; }
  .form h1 {
    font-family: "Roboto Slab", serif;
    font-size: 16px;
    font-weight: bold;
    clear: both; }
    .form h1 span {
      float: right;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #b3b3b3;
      margin-top: 6px; }
  .form hr {
    clear: both;
    border: none;
    background: url("../img/dots-menu_subpages.png") repeat-x left top;
    height: 1px; }
    .form hr + h1 {
      margin-top: 60px; }
  .form .btn#mybut {
    margin-top: 40px; }
  .form .form-row:after {
    content: "";
    display: table;
    clear: both; }
  .form .form-row .col-100 {
    width: 100%; }
  .form .form-row .col-50 {
    width: 50%;
    float: left;
    padding-right: 20px;
    box-sizing: border-box; }
    .form .form-row .col-50:nth-of-type(2) {
      padding-right: 0px;
      padding-left: 20px; }
    @media (min-width: 0) and (max-width: 960px) {
      .form .form-row .col-50 {
        float: none;
        width: 100%;
        padding: 0px !important; } }
  .form .form-row table {
    margin-top: 20px; }
    .form .form-row table td {
      font-size: 13px;
      padding: 5px 0px;
      padding-right: 20px;
      line-height: 20px; }
      .form .form-row table td .form-radio {
        padding: 0px;
        margin: 0px; }
        .form .form-row table td .form-radio .radio {
          float: left;
          width: 60px;
          margin: 0px; }
  .form .form-row .file {
    padding: 20px 0px 20px 0px;
    display: block;
    font-size: 13px; }
    .form .form-row .file:after {
      content: "";
      display: table;
      clear: both; }
    .form .form-row .file span {
      float: left;
      margin-right: 10px;
      height: 35px;
      line-height: 35px; }
    .form .form-row .file input {
      opacity: 0;
      width: 1px;
      height: 1px;
      position: absolute;
      transform: translate(20px, 20px); }
    .form .form-row .file #fileInfo {
      clear: both;
      height: 20px;
      width: 100%;
      line-height: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 13px; }
  .form .form-row.captcha-row {
    margin-top: 25px; }

.radio input {
  opacity: 0; }

.radio input:checked ~ .helper:before {
  color: #202139; }

.radio input:checked ~ .helper:after {
  border-color: #202139;
  background-color: #202139; }

.emptyfielderror, .captchaerror {
  margin: 10px 0px;
  display: none;
  color: #da1f4f;
  font-size: 13px;
  font-family: "Open Sans", sans-serif; }

.form .success {
  display: none;
  clear: both; }
  .form .success h2 {
    font-family: Roboto Slab,serif;
    font-size: 16px;
    font-weight: 700;
    clear: both; }
  .form .success p {
    font-size: 13px;
    line-height: 20px;
    font-family: Open Sans,sans-serif;
    padding: 0 0 1.5em;
    margin: 0; }

article {
    a {
        color: inherit;
        text-decoration: none;
      
        &:hover{
          h1{
            text-decoration: underline;
          }
        }
    }

    .category {
        color: $pink-main;
        text-transform: uppercase;
    }

    .postdate {
        font-style: italic;
    }

    h4 {
        margin: 0;
        padding: 0;
        font-family: $opensans;
        font-size: 11px;
        margin-bottom: 5px;
    }

    h1 {
        font-family: $roboto;
        font-weight: bold;
        font-size: 16px;
        line-height: 25px;
        color: $purple-main;
        margin: 0;
        padding: 0;
        margin-bottom: 5px;
    }

    p {
        font-family: $opensans;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 12px;
        color: $purple-main;
        margin: 0;
        padding: 0;
        font-family: $playfair;
        font-style: italic;
        font-weight: normal;
    }

    &.huge {
        h4 {
            font-size: 12px;
            margin-bottom: 10px;
            @include breakpoint($big) {
                font-size: 16px;
                letter-spacing: 1px;
                margin-bottom: 20px;
            }
        }

        h1 {
            font-size: 28px;
            line-height: 30px;
            font-family: $playfair;
            font-weight: normal;
            letter-spacing: -0.005em;
            margin-bottom: 20px;
            @include breakpoint($big) {
                font-size: 73px;
                line-height: 75px;
                margin-bottom: 25px;
            }
        }

        p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 20px;
            font-family: $playfair;
            font-weight: normal;
            letter-spacing: -0.005em;
            max-width: 640px;
            @include breakpoint($big) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 25px;
            }
        }

        h3 {
            font-size: 13px;
            @include breakpoint($big) {
                font-size: 15px;
            }
        }
    }

    &.tweet {
        h1 {
            font-size: 11px;
            font-family: $opensans;
            line-height: 16px;
            padding-left: 16px;
            background: url("../img/ico_tweet_dark.png") no-repeat left center;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &.person {
        h1,
        h2,
        h3 {
            font-size: 11px;
            line-height: 14px;
            margin: 0;
            padding: 0;
            color: $purple-main;
            font-family: $opensans;
            font-weight: 600;
        }

        h1 {
            font-family: $roboto;
            font-weight: bold;
        }

        hr {
            display: block;
            border: none;
            width: 20px;
            height: 1px;
            padding: 0;
            margin: 10px 0;
            background: $dots_article repeat-x left center;
        }

        p {
            font-size: 11px;
            line-height: 16px;
        }
    }
}

.article_grid {
    @include clearfix;
    @include breakpoint($big) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    padding-bottom: 55px;

    article {
        @include breakpoint($big) {
            width: 300px;
        }
        box-sizing: border-box;
        padding: 20px 20px 20px 0;
        margin-right: 23px;
        background: $dots_article bottom left repeat-x;

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}

.article_list {
    padding: 22px 0 23px;
    background: $dots_article bottom left repeat-x;

    h4 {
        color: $pink-main;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-family: $opensans;
        font-size: 11px;
        margin-bottom: 10px;
    }

    article {
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

sidebar {
    article {
        padding: 20px 0;
        width: 100%;
        background: $dots_article bottom left repeat-x;

        p {
            color: $purple-grey;
        }
    }
}

@function vw-mobile($valueinpx){
    @return (($valueinpx / 640) * 100) + vw;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
